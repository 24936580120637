:root {
	--lato: 'Lato', sans-serif;
	--lora: 'Lora', serif;
	--kaushan: 'Kaushan Script', cursive;
	--lightest: #f1f0ec;
	--light-grey: #c8b6aa;
	--dark-grey: #484736;
	--main-font: #5a5944;
}

body {
	font-family: var(--lato);
	margin: 0;
	padding: 0;
}

a {
	color: var(--main-font);
	text-decoration: underline;

	&:visited {
		color: var(--main-font);
	}

	&:hover, &:active, &:focus {
		color: #000000;
	}
}

.wrapper {
  box-sizing: border-box;
  max-width: 100%;
  margin: 0 auto;
  padding: 20px 20px;
  width: 1230px;
}

header {
	display: flex;
	justify-content: space-between;
	padding-top: 25px;
	width: 100%;
}

.logo {
	font-family: var(--lora);
	font-size: 38px;
	letter-spacing: 3px;
	margin: 0;

	a {
		color: var(--main-font);
		text-decoration: none;
	}

	span {
		display: block;
		font-family: var(--lato);
		font-size: 18px;
		text-align: center;
    letter-spacing: 3px;
		margin-top: 10px;
	}
}

.contact {
	background-color: var(--lightest);
	border-radius: 12px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 110px;
	width: 250px;

	p {
		color: var(--dark-grey);
		font-family: var(--lato);
		margin: 0;
		padding: 0;
		text-align: center;

		a {
			color: var(--dark-grey);
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
	}
}

p {
	font-size: 1em;
	padding-bottom: 0;
}


.nav {
	background-color:#757458;
	margin: 0;
}

.nav2 {
	color:#ffffff;
	box-sizing: border-box;
	font-size: 1em;
	max-width: 100%;
	margin: 0 auto;
	padding: 0 20px;
	width: 1230px;

	ul {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	li {
		display: inline-block;
		letter-spacing: .5px;
		margin-right: 15px;
		padding: 15px 20px 15px 20px;
		text-transform: uppercase;

		a {
			display:inline-block;
			color:#FFF;
			text-decoration:none;

			&:hover {
				text-decoration: underline;
			}
		}

		&.active {
			background-color: var(--lightest);
			color: var(--main-font);
		}
	}

}

h2 {
	color: #a09188;
	font-family: var(--lora);
	font-size: 1.8em;
	margin: 30px 0;
}

h3 {
  font-size: 1.35em;
	margin-bottom: 5px;
}

h3 + p {
	margin-top: 0;
}

main {
	color: var(--main-font);
	display: flex;
	justify-content: space-between;
	line-height: 1.6em;

	ul {
		margin-top: -10px;
	}
}

/* blockquote {
	color:#E26897;
	font-style: italic;
	padding: 0 30px;
} */

.content {
	box-sizing: border-box;
	width: calc( 100% - 280px );
	margin-bottom: 40px;
}

.imageColumn {
	width: 250px;
	padding-top:15px;
}

.jane-casey {
	width: 100%;
	border-radius: 12px;
}

.schp {
	display: block;
	margin: 20px auto;
}

footer {
	background-color: var(--light-grey);
	line-height: 1.4em;
	margin: 0;
}

.footer {
	color: var(--dark-grey);
	padding: 20px;
	text-align:center;

	a {
		color: var(--dark-grey);
	}
}

.small {
	font-size: 13px;
}


/****************************************************
MEDIA QUERIES
*****************************************************/

@media screen and (min-width: 751px) {

	p {
		font-size: 1.2em;
		padding-bottom: 0;
	}

	ul {
		font-size: 1.2em;
	}
}

@media screen and (max-width: 750px) {

	header {
		flex-wrap: wrap;
		padding-top: 20px;
	}

	.logo {
		text-align: center;
		margin-bottom: 40px;
		width: 100%;
	}

	.contact {
		width: 100%;
	}

	.content {
		width: 100%;
	}

	.nav2 {

		ul {
			text-align: center;
		}

		li {
			margin-left: 7px;
			margin-right: 7px;
		}

	}

}

@media screen and (min-width: 1230px) {
	.mobile-only {
		display: none;
	}
}

@media (max-width: 1229px) {
	.desktop-only {
		display: none;
	}
}

@media (min-width: 500px) {

	.content {
		padding-right: 60px;
	}

	.img-mobile {
		display: none;
	}

}

@media (max-width: 499px) {

	main {
		flex-wrap: wrap;
	}

	.imageColumn {
		width: 100%;
	}

	.img-desktop {
		display: none;
	}

	.jane-casey {
		margin-bottom: 40px;
	}

	.schp {
		margin: 0 auto 40px;
	}

}
